import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Form, Input, message, Select, Tag } from "antd";
import { OpenAI } from "openai";
import React, { useEffect, useState } from "react";
import Progress from "react-progress-2";
import { useParams } from "react-router-dom";
import { PostDataAuth } from "../../../apiService/PostData";
import { uploadAdapter } from "../../../helpers/uploadAdapter";
import useCourseLevels from "../../../hooks/useCourseLevels";
import useIntakeMonths from "../../../hooks/useIntakeMonths";
import useSubjects from "../../../hooks/useSubjects";
import { apis } from "../../../properties";

const { Option } = Select;

const SingleCourseView = () => {
  const params = useParams();
  const { id, pageKey, courseId } = params;
  const [selectedData, setSelectedData] = useState();
  const [countryId, setCountryId] = useState({});
  const [overviewData, setOverviewData] = useState();
  const [entryRequirementsData, setEntryRequirementsData] = useState();
  const [priceDetails, setPriceDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [btnLoading, setBtnLoading] = useState({
    overViewAI: false,
    entryRequirementsAI: false,
    updateJson: false,
  });
  const [form] = Form.useForm();

  const [filterDataOther] = useState({
    status: 1,
    isStore: true,
  });

  const courseLevels = useCourseLevels(filterDataOther);
  const subjects = useSubjects(filterDataOther);
  const intakeMonths = useIntakeMonths(filterDataOther);

  useEffect(() => {
    const getScrapData = async () => {
      Progress.show();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/get-scarp-json/single-course/${courseId}?country=${pageKey}`
      );
      const data = await response.json();
      Progress.hide();
      setOverviewData(data?.courseObj?.overview);
      setEntryRequirementsData(data?.courseObj?.entryRequirements);
      setSelectedData(data?.courseObj);
      switch (pageKey) {
        case "AU":
          setCountryId(13);
          break;
        case "CA":
          setCountryId(38);
          break;
        case "NZ":
          setCountryId(157);
          break;
        case "UK":
          setCountryId(230);
          break;
        default:
          setCountryId(230);
      }

      setPriceDetails(data?.courseObj?.studyOptions?.details);

      form.setFieldsValue({
        title: data?.courseObj?.title,
        courseLevel: data?.courseObj?.courseLevel ?? null,
        intakeMonths: data?.courseObj?.intakeMonths ?? [],
        subject: data?.courseObj?.subject ?? null,
      });

      if (data?.courseObj?.subject !== null) {
        subjects.forEach(
          (subject) =>
            subject.name === data?.courseObj?.subjectName &&
            form.setFieldsValue({ subject: subject.id })
        );
      }

      data?.courseObj?.studyOptions?.details.forEach((studyOption, index) => {
        form.setFieldsValue({
          [`typeAndDuration-${index}`]: studyOption.typeAndDuration,
          [`startDates-${index}`]: studyOption.startDate,
          [`venue-${index}`]: studyOption.venue,
          [`price-${index}`]: studyOption.price.replace(/[^\d.]/g, ""),
        });
      });
    };
    getScrapData();
  }, [id, pageKey, courseId, subjects, form, refresh]);

  const handleAICheck = async (contentText, type) => {
    if (type === "overview") {
      setBtnLoading({ ...btnLoading, overViewAI: true });
    } else {
      setBtnLoading({ ...btnLoading, entryRequirementsAI: true });
    }
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      const response = await openai.chat.completions.create({
        messages: [
          {
            role: "user",
            content: `Rewrite the following text: ${contentText}`,
          },
        ],
        model: "gpt-3.5-turbo",
      });

      console.log(response.choices[0].message.content);
      setSelectedData((prevData) => ({
        ...prevData,
        [type]: response.choices[0].message.content,
      }));
      if (type === "overview") {
        setBtnLoading({ ...btnLoading, overViewAI: false });
        setOverviewData(response.choices[0].message.content);
      } else {
        setBtnLoading({ ...btnLoading, entryRequirementsAI: false });
        setEntryRequirementsData(response.choices[0].message.content);
      }

      message.success("AI check completed successfully");
    } catch (error) {
      console.log("Error in AI check: ", error);
      message.error("Error in AI check");
    }
  };

  const handleSubmit = async () => {
    if (
      form.getFieldValue("intakeMonths") === null ||
      form.getFieldValue("intakeMonths").length === 0
    ) {
      message.error("Please select intake months");
      return;
    }

    if (
      form.getFieldValue("subject") === null ||
      form.getFieldValue("subject") === undefined
    ) {
      message.error("Please select subject");
      return;
    }

    if (
      form.getFieldValue("courseLevel") === null ||
      form.getFieldValue("courseLevel") === undefined
    ) {
      message.error("Please select course level");
      return;
    }

    const postData = {
      ...selectedData,
      uniId: id,
      country: params.pageKey,
      overview: overviewData,
      entryRequirements: entryRequirementsData,
      title: form.getFieldValue("title"),
      subject: form.getFieldValue("subject"),
      courseLevel: form.getFieldValue("courseLevel"),
      intakeMonths: form.getFieldValue("intakeMonths"),
    };
    setBtnLoading({ ...btnLoading, updateJson: true });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/get-scarp-json/update-uni-object`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    );
    const data = await response.json();

    if (data.status === "error") {
      message.error(data.message);
      setBtnLoading({ ...btnLoading, updateJson: false });
      setRefresh(!refresh);
      return;
    }
    await handleSaveAndSync();
    setRefresh(!refresh);
    setBtnLoading({ ...btnLoading, updateJson: false });
    message.success(data.message);
  };

  const handleSaveAndSync = async () => {
    const intakes = [];
    form.getFieldValue("intakeMonths").forEach((month) => {
      intakes.push({
        month_id: month,
        year_id: 4,
        course_limit: 30,
      });
    });

    const priceDetails = selectedData.studyOptions?.details[0]?.price.replace(
      /[^\d.]/g,
      ""
    );

    const postData = {
      ...selectedData,
      uniId: id,
      overview: overviewData,
      entryRequirements: entryRequirementsData,
      title: form.getFieldValue("title"),
      subjects: form.getFieldValue("subject"),
      courseLevel: form.getFieldValue("courseLevel"),
      intakes: intakes,
      country_id: countryId,
      name: selectedData.uniName,
      course_fee: Number(priceDetails),
      startDate: selectedData.studyOptions?.details[0]?.startDate,
      typeAndDuration: selectedData.studyOptions?.details[0]?.typeAndDuration,
    };

    Progress.show();
    PostDataAuth(apis.SYNC_SCRAP_DATA, postData).then((result) => {
      let responseJson = result;
      if (responseJson.status === "success") {
        Progress.hide();
        message.success(responseJson.message);
      } else if (responseJson.status === "error") {
        Progress.hide();
        message.error(responseJson.message);
      }
    });
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="row_2">
          <p className="common_header">
            {pageKey ?? ""} | {selectedData?.uniName} |{" "}
            <span>{selectedData?.title}</span>
          </p>
          <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
            <Tag color={selectedData?.isChecked ? "green" : "red"}>
              {selectedData?.isChecked ? "Synced" : "Not Synced"}
            </Tag>
            <Button type="default" onClick={() => window.history.back()}>
              Back
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={btnLoading.updateJson}
            >
              Save and Sync
            </Button>
          </div>
        </div>
        <Form
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={(error) => message.error(error)}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Form.Item name="title" label="Title">
            <Input type="text" size="large" placeholder="Title" />
          </Form.Item>

          {priceDetails?.map((studyOption, index) => {
            return (
              <div
                key={index}
                style={{ padding: 20, backgroundColor: "whitesmoke" }}
              >
                <p>Study Option {index + 1}</p>
                <Form.Item
                  label="Type And Duration"
                  name={`typeAndDuration-${index}`}
                >
                  <Input
                    type="text"
                    size="large"
                    onChange={(e) =>
                      (selectedData.studyOptions.details[
                        index
                      ].typeAndDuration = e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item label="Start Date" name={`startDates-${index}`}>
                  <Input
                    type="text"
                    size="large"
                    onChange={(e) =>
                      (selectedData.studyOptions.details[index].startDate =
                        e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item label="Venue" name={`venue-${index}`}>
                  <Input
                    type="text"
                    size="large"
                    onChange={(e) =>
                      (selectedData.studyOptions.details[index].venue =
                        e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item label="Price" name={`price-${index}`}>
                  <Input
                    type="text"
                    size="large"
                    onChange={(e) =>
                      (selectedData.studyOptions.details[index].price =
                        e.target.value)
                    }
                  />
                </Form.Item>
              </div>
            );
          })}

          <Form.Item name="intakeMonths" label="Intake Month">
            <Select
              placeholder="Select Intake Month"
              allowClear
              size="large"
              mode="multiple"
            >
              {intakeMonths.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.months}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="courseLevel" label="Course Level">
            <Select placeholder="Select Course Level" allowClear size="large">
              {courseLevels.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.level}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="subject" label="Subject">
            <Select placeholder="Select Subject" allowClear size="large">
              {subjects.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="overview" label="Overview">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Overview",
              }}
              editor={ClassicEditor}
              data={overviewData}
              onChange={(event, editor) => {
                const data = editor.getData();
                setOverviewData(data);
              }}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            onClick={() => handleAICheck(overviewData, "overview")}
            loading={btnLoading.overViewAI}
          >
            Rewrite Overview Using AI
          </Button>

          <Form.Item name="entryRequirements" label="Entry Requirements">
            <CKEditor
              config={{
                extraPlugins: [uploadPlugin],
                placeholder: "Entry Requirements",
              }}
              editor={ClassicEditor}
              data={entryRequirementsData}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEntryRequirementsData(data);
              }}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
            />
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              handleAICheck(entryRequirementsData, "entryRequirements")
            }
            loading={btnLoading.entryRequirementsAI}
          >
            Rewrite Entry Requirements Using AI
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SingleCourseView;
